import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useEffect, useState } from "react";
import DefaultInput from "components/FormInputs/DefaultInput";
import InfoPopover from "components/FormComponentsNew/InfoPopover";

const isValidRate = (value) => {
  if (!Number.isInteger(value) || String(value).includes(".")) {
    return false;
  }

  return true;
};

const rateSchema = Yup.object().shape({
  ratePerHour: Yup.number()
    // To prevent showing an error about not casting the data type properly
    .transform((value, originalValue) => {
      return originalValue === "" ? undefined : value;
    })
    .max(200, "Enter a value less than or equal to $200")
    .min(1, "Enter at least $1")
    .required("This field is required")
    .test("integer", "Please enter an integer", isValidRate),
});

const Rate = ({ user, className, rateRef, save, setDisabled }) => {
  const [monthlyRate, setMonthlyRate] = useState(0);

  const methods = useForm({
    resolver: yupResolver(rateSchema),
    defaultValues: {
      ratePerHour: user?.ratePerHour?.value || 0,
    },
    mode: "all",
  });

  const { getFieldState, watch } = methods;

  const watchedValue = watch("ratePerHour");

  const handleBlur = ({ target }) => {
    const key = target.id;
    const value = target.value;

    if (getFieldState(key).error) {
      return;
    }

    if (value > 0) {
      save(
        {
          ratePerHour: {
            value,
            currency: "USD",
          },
        },
        true
      );
    }
  };

  useEffect(() => {
    if (
      watchedValue &&
      watchedValue > 0 &&
      watchedValue <= 200 &&
      isValidRate(Number(watchedValue))
    ) {
      setDisabled(false);
      setMonthlyRate(watchedValue * 173);
    } else {
      setDisabled(true);
      setMonthlyRate(0);
    }
  }, [watchedValue, setDisabled]);

  return (
    <div className={className} ref={rateRef}>
      <div className="b1 mb-2">
        Add your expected hourly rate in U.S. Dollars (USD)
      </div>
      <p className="b4-bold">
        Remember you are an hourly contractor. Your rate should factor in
        vacations, holidays
        <br />
        etc. Please enter your hourly rate in the box.
      </p>
      <div className="b4">
        You'll be able to adjust this rate when you apply to specific jobs.
      </div>
      <FormProvider {...methods}>
        <form
          className="sm:w-2/3 w-4/5 flex justify-between mt-16"
          onBlur={handleBlur}
        >
          <DefaultInput
            type="number"
            id="ratePerHour"
            label="Rate/Hour (in $)"
            defaultValue={user?.ratePerHour?.value ?? 0}
          />

          <div className="flex gap-4 items-center">
            <DefaultInput
              type="number"
              id="monthlyRate"
              label="Monthly Rate (in $)"
              disabled={true}
              min={1}
              placeholder="0"
              value={monthlyRate}
            />
            <InfoPopover>
              Monthly rate is calculated as
              <br />
              173 hours * hourly rate
            </InfoPopover>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Rate;
