import { useMemo } from "react";
import classNames from "classnames";

import CandidateCard from "./CandidateCard";
import DropDown from "components/buttons/DropDown";
import { JOB_APPLICATION_MATCH_STATUS } from "lookup";
import NotesWrapper from "components/Notes";
import ProfileDetails from "pages/Profile/Public/ProfileDetails";
import Ratings from "components/Ratings";

export default function CandidateProfile({
  match,
  jobOpp,
  showMatchSummary,
  user,
  updateJobOppMatchState,
  deleteJobOppMatchFromState,
  handleRatingClick,
  jobCalendarEvents,
  minScore,
  isReadOnly,
  candidateRate,
  noteType,
  hideDownloadPDFLink,
  hideMatchActions,
  modals,
  setModalDisplay,
}) {
  const matchStatus = match.status;
  const rating = match?.rating;

  const onStatusChange = (changedStatus) => {
    if (changedStatus === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER) {
      deleteJobOppMatchFromState({ ...match });
    } else {
      updateJobOppMatchState({ ...match, status: changedStatus });
    }
  };

  const candidateHasMeetings = useMemo(
    () => jobCalendarEvents?.some((e) => e.title.includes(user.username)),
    [jobCalendarEvents, user]
  );

  const isHired = useMemo(
    () => matchStatus === JOB_APPLICATION_MATCH_STATUS.ACCEPTED,
    [matchStatus]
  );

  const setDenyModal = () => {
    setModalDisplay(
      modals["deny"].renderCustomContent({
        application: match,
        jobId: jobOpp?.id,
        applicationId: match?.applicationId,
        onStatusChange,
        isReadOnly,
      })
    );
  };

  const setAcceptModal = () => {
    setModalDisplay(
      modals["accept"].renderCustomContent({
        application: match,
        jobId: jobOpp?.id,
        applicationId: match?.applicationId,
        onStatusChange,
      })
    );
  };

  const statusUpdateCTAItems = user.slugScheduler
    ? [
        { id: 1, label: "Hire", color: "gray-700" },
        {
          id: 2,
          label: candidateHasMeetings
            ? "Request another meeting"
            : "Request a meeting",
          color: "gray-700",
        },
        { id: 3, label: "Decline", color: "red-500" },
      ]
    : [
        { id: 1, label: "Hire", color: "gray-700" },
        { id: 2, label: "Decline", color: "red-500" },
      ];

  const statusUpdateCTAHandlers = user.slugScheduler
    ? [
        () => setAcceptModal(),
        () =>
          setModalDisplay(
            modals["scheduler"].renderCustomContent({
              applicant: match.user,
              jobOpp,
            })
          ),
        () => setDenyModal(),
      ]
    : [() => setAcceptModal(), () => setDenyModal()];
  return (
    <>
      <div className="w-full max-w-5xl rounded-sm">
        <div
          className={classNames(
            "px-12 pt-6 pb-4 border-b-2 border-gray-100 flex flex-col font-bold text-gray-400 justify-center",
            {
              "bg-white": !isHired,
              "bg-emerald-50": isHired,
            }
          )}
        >
          <div className="flex">
            <div>Reaction:</div>
            <Ratings
              rating={rating}
              handleClickRating={(selectedRating) =>
                handleRatingClick(match, selectedRating)
              }
              isReadOnly={isReadOnly}
            />
            {!isReadOnly && !hideMatchActions && (
              <div className="ml-auto">
                <DropDown
                  defaultValue={
                    user.slugScheduler ? "Request a meeting" : "Hire"
                  }
                  items={statusUpdateCTAItems}
                  handleClick={statusUpdateCTAHandlers}
                  className="min-w-[183px]"
                />
              </div>
            )}
            {isHired && (
              <div className="ml-auto rounded-lg font-nexa bg-emerald-500 px-3 py-3 rounded-xs text-white w-[183px] text-center">
                HIRED
              </div>
            )}
          </div>

          {match?.calibrationNote && (
            <div className="flex mt-6 text-md">
              <div className="mr-2">
                {match.calibrationNote?.creator} says:{" "}
              </div>
              <div className="text-gray-600">
                {match.calibrationNote?.content}
              </div>
            </div>
          )}
        </div>
        <div className="bg-white border-b-2 border-gray-100 px-12 py-8 relative">
          <CandidateCard
            candidate={match?.user}
            size="large"
            rate={candidateRate}
          />
          <NotesWrapper
            jobOpportunityId={jobOpp?.id}
            applicationId={match?.applicationId}
            className="absolute top-4 right-4"
            name={`${user.given_name} ${user.family_name}`}
            isReadOnly={isReadOnly}
            noteType={noteType}
          />
        </div>

        <div className="bg-white px-4 pb-6 pt-2">
          <ProfileDetails
            appsyncUser={user}
            applicationFieldHistory={match?.fieldHistory}
            showPdf
            renderBadges
            renderCustomLinks
            showGithubStats
            showAssessmentLinks
            minScore={minScore}
            matchSummary={showMatchSummary && match.customerSummary}
            hideDownloadPDFLink={hideDownloadPDFLink}
          />
        </div>
      </div>
    </>
  );
}
