export const SKILL_EXPERIENCE = {
  low: {
    label: "0 to 2 years",
    briefLabel: "0-2y",
  },
  medium: {
    label: "2 to 5 years",
    briefLabel: "2-5y",
  },
  high: {
    label: "5+ years",
    briefLabel: "5+y",
  },
};
